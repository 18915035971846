import React from "react";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import { Container, Col, Row } from "reactstrap";
// index sections
import News from "./Content/News";
// import Cooperation from "./Cooperation";
// import Research from "./Research";
import Cooperation from "./Homepage/Cooperation";
import Research from "./Homepage/Research";
import ResearchGroup from "./Homepage/ResearchGroup";
import Journal from "./Journal";
// css 
import "../assets/css/custom.css"





function Index() {

   
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");

     // EMC Tracking Code
     var _govaq = window._govaq || [];
     _govaq.push(['trackPageView']);
     _govaq.push(['enableLinkTracking']);
     _govaq.push(['setTrackerUrl', 'https://f-emc.ngsp.gov.vn/tracking']);
     _govaq.push(['setSiteId', '4139']);
     (function () {
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = 'https://f-emc.ngsp.gov.vn/embed/gov-tracking.min.js';
      s.parentNode.insertBefore(g, s);
    })();


    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  
  return (
    <>
      <IndexHeader height={830} ></IndexHeader>
      <News></News>
      <Research></Research>
       <Cooperation></Cooperation>
      <Journal style={{marginBottom:'3rem'}}></Journal>
      <ResearchGroup></ResearchGroup>
      
    </>
  );
}

export default Index;
